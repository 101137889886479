import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "theme",
      "style": {
        "position": "relative"
      }
    }}>{`Theme`}</h1>
    <p>{`Bold exports two theme objects: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lightTheme`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`darkTheme`}</code>{`, using the former as default.`}</p>
    <p>{`You can switch this site documentation theme between light/dark by using the light bulb icon on the top right corner of the page.`}</p>
    <h2 {...{
      "id": "customizing-the-theme",
      "style": {
        "position": "relative"
      }
    }}>{`Customizing the theme`}</h2>
    <p>{`The theme can be changed using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ThemeProvider`}</code>{` component:`}</p>
    <Demo src='pages/usage/theme/Customize' mdxType="Demo" />
    <h2 {...{
      "id": "accessing-the-current-theme-variable",
      "style": {
        "position": "relative"
      }
    }}>{`Accessing the current theme variable`}</h2>
    <p>{`Use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useTheme`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useStyles`}</code>{` hooks to access the theme variable:`}</p>
    <Demo src='pages/usage/theme/AccessTheme' mdxType="Demo" />
    <h2 {...{
      "id": "custom-themes",
      "style": {
        "position": "relative"
      }
    }}>{`Custom themes`}</h2>
    <p>{`Create a custom theme using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createTheme`}</code>{` function.`}</p>
    <Demo src='pages/usage/theme/CustomTheme' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      